<template>
  <div class="flex flex-col mt-1 bg-gray-100 px-4 pt-12 mb-20  w-full min-w-0 relative">
       <div class="bg-white w-full rounded-lg shadow">
          <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
            <div class="flex">
              <router-link :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
                to="/expeditions">
                <i class="material-icons mx-2">chevron_left</i>
              </router-link>
              <div v-if="!formData._id" class="text-2xl font-bold text-green-500 ">{{$t('new_expidition')}}
              </div>
              <div v-else class="text-2xl font-bold text-green-500 ">{{$t('edit_expidition')}}
              </div>
            </div>
          </div>
            <!-- ************************************************************************************ -->
            <!-- ************************************Select Colis***********************************-->
            <div class="px-6 mt-3">
              <div class="flex w-full space-x-2">
                <div class="flex w-full justify-between shadow-xs items-center h-auto p-6 my-1 mb-1 rounded-lg">
                  <div class="flex w-full">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                      <i class="material-icons p-3 text-green-400 ">person</i>
                    </span>

                    <div class="ml-5 w-full">
                      <div class="text-base mt-1 font-semibold text-gray-600 pb-10">
                        {{$t('package_information')}}
                      </div>
                      <div class="text-sm font-light text-gray-500"></div>
                      <div class="w-full space-y-4">
                        <div class="grid lg:grid-cols-2 gap-6">  
                          <div :class="!formData._id && !formData.country?'animation-pulse':''" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerPhone" class="bg-white text-gray-700 px-1">{{$t('from')}}
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <v-select @input="setSelectedFromContry" v-model="formData.country" :disabled="formData._id && formData.status == 'received' ?true:false" :options="options" label="name">
                                <template slot="option" slot-scope="option">
                                    <img class="flag-img" :src="$f.getCountryCode(option.code)"/>
                                    {{ option.name }} 
                                </template>
                            </v-select>
                          </p>
                        </div>

                          <div v-if="(formData._id && formData.type && formData.type == 'transfer') || !formData._id"  class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="customerFullName" class="bg-white text-gray-700 px-1">  {{$t('number_of_packages')}}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="customerFullName" autocomplete="false" tabindex="0" type="number" min="1"
                                v-model="formData.numberOfPackages"
                                class="py-2 text-xs px-2 outline-none block  h-full w-full"  />
                            </p>
                          </div>
                         

                        
                      
                       
                      
                         <div v-if="formData.country" :class="!formData._id && !formData.country_to?'animation-pulse':''" class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerPhone" class="bg-white text-gray-700 px-1">{{$t('to')}}
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <v-select @input="setSelectedToContry" v-model="formData.country_to" :disabled="formData._id && formData.status == 'received' ?true:false" :options="warehouses" label="name">
                                <template slot="option" slot-scope="option">
                                    <img class="flag-img" :src="$f.getCountryCode(option.code)"/>
                                    {{ option.name }} 
                                </template>
                            </v-select>
                          </p>
                        </div>

                        <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerAddress" class="bg-white text-gray-700 px-1">{{$t('expedition_date')}}
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <input id="dateexp" autocomplete="false" tabindex="0" type="datetime-local" v-model="formData.date"
                              class="py-2 text-xs px-2 outline-none block h-full w-full" />
                          </p>
                        </div>

                        <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                            <p>
                              <label for="customerShippingAddress" class="bg-white text-gray-700 px-1">{{$t('transport_mode')}}
                                <span :class="$colors.required">*</span></label>
                            </p>
                          </div>
                          <p>
                            <select id="type" name="seller" autocomplete="type" v-model="formData.transportMode"
                              class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                              <option selected disabled :value="null">{{$t('transport_mode')}}</option>
                              <option v-for="(trans, index) in transports" :key="index" :value="trans">
                                {{ trans }}
                              </option>
                            </select>
                          </p>
                        </div>
                        <div  class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="carrier" class="bg-white text-gray-700 px-1">  {{$t('Nom de Fournisseur')}}
                                  <span :class="$colors.required">*</span></label>
                              </p>
                            </div>
                            <p>
                              <input id="carrier" autocomplete="false" tabindex="0" type="text" 
                                v-model="formData.carrier.name"
                                class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div>
                          <div  class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                            <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                <label for="carrier" class="bg-white text-gray-700 px-1">  {{$t('Nom de Transitaire')}}</label>
                              </p>
                            </div>
                            <p>
                              <input id="carrier" autocomplete="false" tabindex="0" type="text" 
                                v-model="formData.transitaire"
                                class="py-2 text-xs px-2 outline-none block h-full w-full" />
                            </p>
                          </div>
                       </div> 
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ****************************** Order Information *********************************************-->
              </div>
              <!-- Status and seller Administartion -->
              <div class="bg-white rounded-lg p-6" v-if="currentUser.type !== 'Seller' && formData.country_to">
                  <div class="grid lg:grid-cols-2 gap-6">
                      <div :class="!formData._id && !formData.seller?'animation-pulse':''"  class="border seller-select h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1">{{$t('seller')}}
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>    
                        <p>
                            <v-select  :disabled="formData.status == 'received' ?true:false" label="fullName" @search="searchSellers($event,'exp')" v-model="formData.seller" :options="sellers"></v-select>
                        </p>
                        
                      </div>  
                      <div v-if="formData._id" class="border seller-select h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                        <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                          <p>
                            <label for="seller" class="bg-white text-gray-700 px-1">{{$t('status')}}
                            <span :class="$colors.required">*</span></label>
                          </p>
                        </div>
                        <p>
                           <select :disabled="formData.status && formData.status === 'received'?true:false" name="status" autocomplete="type" v-model="formData.status"
                                class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white">
                                <option class="bg-white" selected :value="null">{{$t('status')}}</option>
                                <option class="bg-white text-gray-600" v-for="(value, index) in status" :key="index" :value="value">
                                    {{ value }}
                                </option>
                            </select>
                        </p>
                      </div> 
                      <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                  <label for="dateexpar" class="bg-white text-gray-700 px-1">{{$t('arrival_date')}}
                                      <span :class="$colors.required">*</span></label>
                              </p>
                          </div>
                          <p>
                              <input id="dateexpar"  type="datetime-local"  v-model="formData.arrivalDate" class="py-2 text-xs px-2 outline-none block h-full w-full" />
                          </p>
                      </div>
                      

                     
                      <!-- <div class="border hover:border-green-500  w-full focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                          <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                              <p>
                                  <label for="customerPhone" class="bg-white text-gray-700 px-1">Paid 
                                      <span :class="$colors.required">*</span></label>
                              </p>
                          </div>
                               <p class="flex items-center justify-center w-full">
                              
                                <label  for="toogleA" class="flex ml-5 items-center cursor-pointer">
                                  <div class="relative">
                                    <input id="toogleA" type="checkbox" class="hidden" v-model="formData.paid" />
                                    <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                                    <div class="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                                  </div>
                                  
                                  <div class="ml-3 text-gray-700">{{ formData.paid  }}</div>
                                </label>
                              </p>
                      </div> -->
                  </div>  
              </div>     

              <!-- ********************************************************Product Table assigned to expedition **********************************-->
              <div class="flex w-full" >
                <div class="flex space-x-2 w-full shadow-xs mt-1 rounded-lg">
                  <div v-if="formData.status!='received' && formData.country && formData.seller && formData.country_to" class="flex w-full justify-between items-center h-auto p-6">
                    <div class="flex w-full">
                      <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-500 ">local_mall</i>
                      </span>
                      <div class="ml-3 w-full p-2 pb-8">
                        <div class="w-full flex text-base font-semibold text-gray-600">
                          <p class="w-1/2">{{$t('products')}}</p>
                          <div class="w-full">
                            
                            <button @click="OpenPopUp"
                               :class="!formData._id && formData.details.length==0?'animation-pulse':''"
                               class="flex text-white mx-auto max-w-sm rounded-md text-center bg-green-600  py-2 px-8 items-center focus:outline-none md:float-right">
                               {{$t('select_product')}}
                            </button>
                          </div>
                        </div>

                        <div class="mt-4 overflow-x-auto ">
                       
                          <table v-if="formData.details" class="min-w-full table item leading-normal relative">
                            <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                            <thead>
                              <tr>
                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  ID
                                </th>
                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{$t('name')}}
                                </th>
                                <th v-if="!formData._id"
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{$t("Prix d'achat")}}
                                </th>
                                  <!-- <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{$t('commodity_value')}} <sup v-if="wharhouse" class="text-black">({{wharhouse.currency }})</sup>
                                </th> -->

                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                  {{$t('quantity')}}
                                </th>

                                <th
                                  class="px-3 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                    {{$t('actions')}}
                                </th>
                              </tr>
                            </thead>
                            <tbody v-for="(item, index) in formData.details" :key="index" >
                              <tr>
                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                    <b>{{ item.product.id }}</b>
                                  </div>
                                </td>
                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">

                                  <!---    -->
                                  <div class="w-full">
                                          <div class="flex">
                                            <svg class="fill-current mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7 24h-5v-9h5v1.735c.638-.198 1.322-.495 1.765-.689.642-.28 1.259-.417 1.887-.417 1.214 0 2.205.499 4.303 1.205.64.214 1.076.716 1.175 1.306 1.124-.863 2.92-2.257 2.937-2.27.357-.284.773-.434 1.2-.434.952 0 1.751.763 1.751 1.708 0 .49-.219.977-.627 1.356-1.378 1.28-2.445 2.233-3.387 3.074-.56.501-1.066.952-1.548 1.393-.749.687-1.518 1.006-2.421 1.006-.405 0-.832-.065-1.308-.2-2.773-.783-4.484-1.036-5.727-1.105v1.332zm-1-8h-3v7h3v-7zm1 5.664c2.092.118 4.405.696 5.999 1.147.817.231 1.761.354 2.782-.581 1.279-1.172 2.722-2.413 4.929-4.463.824-.765-.178-1.783-1.022-1.113 0 0-2.961 2.299-3.689 2.843-.379.285-.695.519-1.148.519-.107 0-.223-.013-.349-.042-.655-.151-1.883-.425-2.755-.701-.575-.183-.371-.993.268-.858.447.093 1.594.35 2.201.52 1.017.281 1.276-.867.422-1.152-.562-.19-.537-.198-1.889-.665-1.301-.451-2.214-.753-3.585-.156-.639.278-1.432.616-2.164.814v3.888zm3.79-19.913l3.21-1.751 7 3.86v7.677l-7 3.735-7-3.735v-7.719l3.784-2.064.002-.005.004.002zm2.71 6.015l-5.5-2.864v6.035l5.5 2.935v-6.106zm1 .001v6.105l5.5-2.935v-6l-5.5 2.83zm1.77-2.035l-5.47-2.848-2.202 1.202 5.404 2.813 2.268-1.167zm-4.412-3.425l5.501 2.864 2.042-1.051-5.404-2.979-2.139 1.166z"/></svg>
                                            <div class="text-xs leading-5 capitalize rounded-full text-gray-900 font-bold">{{ item.product.name }}  <span class="inline-flex" v-if="formData.type && formData.type == 'transfer'">(<img :src="$f.getCountryCode(selectFromContry)" class="w-4 h-4 rounded-full">)</span></div> 
                                          </div>  
                                          <span  v-if="formData.type && formData.type == 'transfer'" class="ware_prin"> 
                                                <div :class="!formData._id &&  ((item.productToTransfer && !item.productToTransfer._id) || !item.productToTransfer)?'animation-pulse':''"  class="border ml-6 hover:border-blue-400  w-10/12 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                                      <p>
                                                        <label for="zonessous" class="bg-white text-gray-700 px-1">{{$t('Transférer Produit à')}} <span class="inline-flex">(<img :src="$f.getCountryCode(selectToContry)" class="w-4 h-4  rounded-full">)</span> <span :class="$colors.required">*</span></label>
                                                      </p>
                                                    </div>
                                                    <p>
                                                      <v-select  v-model="item.productToTransfer" :options="resultSearchUsers" placeholder="Transférer Produit à" label="name" class="border border-gray-600">
                                                          <template slot="option" slot-scope="option">
                                                            <img class="flag-img" :src="option.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"/>  {{ option.name }} 
                                                            ( <span v-for="(images, r) in option.details.filter(it => it.warehouse && it.warehouse.is_primary)" :key="r" class="inline-block">
                                                                    <img :src="images.warehouse.flag" class="w-4 h-4 rounded-full mr-2">
                                                              </span>)
                                                          </template>
                                                      </v-select>
                                                    </p>
                                              </div>
                                          </span >
                                    </div>  
                                  <!--   -->

                                </td>

                                <td v-if="!formData._id" class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1">
                                    <div class="w-full rounded-md inline-flex border">
                                      <div class="flex items-center  font-bold text-s  w-100px justify-center border border-gray-300 w-1/4 bg-gray-200"><span> MAD</span></div>
                                      <input type="number" v-model="item.merchandise_value"  min="0" class="w-full focus:outline-none rounded-md bg-white  text-gray-700 focus:text-gray-600 p-2">
                                    </div>
                                  </div>
                                </td>

                                 <!-- <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                 <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900">
                                     <input type="number"  v-model="item.merchandise_value" min="0" class="px-2 py-2 border focus:ring-gray-500 focus:border-gray-900 sm:text-sm border-gray-600 rounded-md focus:outline-none text-gray-900">
                                  </div>
                                </td> -->

                                <td class="px-3 py-2 whitespace-no-wrap border-b border-gray-200">
                                  <div class="flex space-x-6">
                                    <button :class="$colors.textPrimary"
                                      class="bg-green-600  rounded-md w-6 h-6 flex items-center focus:outline-none"
                                      @click="addQuantity(index)">
                                      <span class="font-bold text-2xl text-white">
                                        <i class="material-icons mt-2">add</i>
                                      </span>
                                    </button>

                                     <span :class="$colors.textPrimary" class="text-gray-700 text-sm h-6 flex items-center focus:outline-none">
                                         <input class="focus:outline-none w-12 text-center" type="text" v-model="item.quantity.sent">
                                     </span>

                                    <button :class="$colors.textPrimary" @click="removeQuantity(index)"
                                      class="bg-green-600  rounded-md w-6 h-6 text-white flex items-center focus:outline-none">
                                      <span class="font-bold text-2xl text-white mt-1">
                                        <i class="material-icons">remove</i>
                                      </span>
                                    </button>
                                  </div>
                                  <div class="text-xs leading-5 capitalize px-2 rounded-full text-gray-900"></div>
                                </td>

                                <td class="py-4 whitespace-no-wrap border-b border-gray-200">
                                  <div class="flex justify-center">
                                    <button @click="removeProduct(item, index)" :class="$colors.actionTable"
                                      class="rounded-full flex focus:outline-none w-8 h-8 mr-3 mt-1">
                                      <i class="material-icons mx-1">delete</i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                    <!--*******************  Product Order ***************************************-->
             <div v-if="formData._id && formData.status=='received'" class="flex w-full justify-between items-center h-auto p-6">
                <div class="flex w-full mt-1">
                    <span class="rounded-full h-12 w-12 bg-gray-200">
                        <i class="material-icons p-3 text-green-400 ">local_mall</i>
                    </span>
                    <div class="ml-5 w-full pb-4">
                        <div class="text-base mt-1 font-semibold text-gray-600 pb-10">{{$t('expedition_products')}} </div>
                        <div class="w-full space-y-4">
                          <div v-if="formData.status == 'received'"  class="grid lg:grid-cols-2 gap-6">

                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="weight" class="bg-white text-gray-700 px-1"> {{$t(formData.type && formData.type=='transfer'?$t('number_of_packages'):$t('weight'))}}
                                        <span :class="$colors.required">*</span></label>
                                    </p>
                                    </div>    
                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                    <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                          <svg  v-if="formData.type && formData.type=='transfer'" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>
                                          <span v-else>KG</span> 
                                    </span>
                                    </div>

                                    <input v-if="formData.type && formData.type=='transfer'" disabled type="number" v-model="formData.numberOfPackages" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                    <input v-else disabled type="number" v-model="formData.weight" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                </div>

                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">
                                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                    <p>
                                        <label for="seller" class="bg-white text-gray-700 px-1"> {{$t('expedition_fees')}}
                                        <span :class="$colors.required">*</span></label>
                                    </p>
                                    </div>    
                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                                        </span>
                                    </div>
                                    <input disabled type="number" v-model="formData.fees" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                   
                                  
                                </div>

                            </div>
                            <div class="w-full">
                                <table class="min-w-full w-full">
                                    <thead class="text-white">
                                        <tr class="bg-green-400  flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                            <th class="p-2 justify-center"> {{$t('image')}}</th>
                                            <th class="p-2 justify-center"> {{$t('name')}}</th>
                                            <th v-if="formData.status == 'received'" class="p-2 justify-center"> {{$t(formData.type && formData.type=='transfer'?`Nombre de colis total`:`Le poids total`)}}</th>
                                            <th style=" width: 200px;"  v-if="formData.status == 'received'" class="p-2 justify-center">{{$t(formData.type && formData.type=='transfer'?`Prix de 1 colis`:`Prix de 1 kilo`)}} </th>
                                            <th class="p-2 justify-center">{{$t('quantity_sent')}}</th>
                                            <th colspan="2" class="p-2 justify-center"> {{$t('quantity_received')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="flex-1 sm:flex-none">
                                        <tr v-for="it in formData.details" :key="it._id" class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2">
                                                <div> <img :alt="it.product.name" :src="it.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-16 w-16 rounded-md mx-auto" /> </div>
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                              <b>{{ it.product.name }}</b>

                                            </td>
                                            <td  v-if="formData.status == 'received'" class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">   
                                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                                          <svg  v-if="formData.type && formData.type=='transfer'" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>
                                                           <span v-else>KG</span>
                                                        </span>
                                                    </div>
                                                    <input type="number" :disabled="formData.isProcessed" v-model="it.weightOrPackages" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                                </div>
                                            </td>
                                             <td style=" width: 200px;"   v-if="formData.status == 'received'" class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <div class="border hover:border-green-500  hover:border-green-500  focus-within:border-green-500 flex flex-wrap items-stretch  relative h-15 bg-white items-center rounded">   
                                                    <div class="flex -mr-px justify-center p-1 rounded  shadow ">
                                                        <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                                                            <sup class="uppercase" v-if="idWharhouse"> {{idWharhouse.currency}} </sup><span class="select-none ml-2"><img :src="idWharhouse?idWharhouse.flag:''" class="flag-img"></span>
                                                        </span>
                                                    </div>
                                                    <input  type="number" :disabled="formData.isProcessed" v-model="it.price_one_kiloOrPackage" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded text-sm shadow rounded-l-none px-3 self-center relative outline-none">
                                                </div>
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                 <input id="sent" :disabled="CheckExpeditionRecived" type="number" min="0" v-model="it.quantity.sent" class="py-2 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                            </td>
                                            <td class="border-grey-light text-xs text-center border hover:bg-gray-100 p-2 truncate">
                                                <table class="min-w-full">
                                                   <thead>
                                                       <tr>
                                                         <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">Ok/Good</th>
                                                         <th class="text-center bg-gray-200 px-6 py-3 border border-gray-300 text-left leading-4 text-green-500  tracking-wider">Defective</th>
                                                       </tr>
                                                    </thead>
                                                    <tbody class="bg-white"> 
                                                        <tr> 
                                                          <td class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center"> <input id="Received" :disabled="CheckExpeditionRecived" type="number" min="0" :max="it.quantity.sent" v-model="it.quantity.received" class="py-2 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" /></div></td>
                                                          <td class="px-6 py-4 border whitespace-no-wrap"><div class="flex items-center">
                                                             <input id="Defective" :disabled="CheckExpeditionRecived" type="number" min="0" :max="it.quantity.sent" v-model="it.quantity.defective" class="py-2 border-4 border-light-blue-500 border-opacity-100  text-xs px-2 outline-none block h-full w-full" />
                                                          </div>
                                                          </td>    
                                                       </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </div>
              </div>
              <!-- ************************************************************************************************-->
            </div>

             
            <!-- ***********************************Pop up Affectation products to Order************************************************ -->
          <vue-modal-2  name="Modal_products" :headerOptions="{title: $t('add_products')}" :noFooter="true" modalSize="lg" @on-close="closePopUp">
              <div class="flex p-5 pt-0 pb-1 flex-col w-full h-auto mb-13">
                <!-- Header -->
                <div class="flex w-full h-auto justify-center items-center">
                  <div class="flex mb-3 w-full h-auto py-2 justify-center items-center text-2xl font-bold">
                    <span class="w-screen md:w-full h-10 cursor-pointer border border-gray-200 rounded text-sm flex">
                      <input type="search" name="search" v-model="searchText" :placeholder="$t('search')"
                        class="flex-grow px-4 rounded-l-full rounded-r-full text-sm focus:outline-none" />
                      <i class="material-icons text-green-400  m-2 mr-5 text-lg w-4 h-4">search</i>
                    </span>
                  </div>
                </div>
                <div>
                  <div v-if="products.length <= 0" class="py-2">
                    <span class="w-full flex justify-center text-center py-20 border-b border-gray-200 text-base">
                      <p class="font-semibold flex text-green-500  whitespace-no-wrap">
                        <span class="">{{$t('no_products_found')}}</span>
                      </p>
                    </span>
                  </div>
                  <div v-for="(item, index) in resultSearchUsers" :key="index" :value="item._id" 
                    class="flex space-x-5 items-center justify-between h-auto py-1 px-5 rounded border border-gray-300 mb-2">
                    <span>
                      <input type="checkbox" class="form-checkbox  inline-block  text-green-500  border-blue-500"
                        @change="addProductsToTemp(item, index)" v-model="item.check" />
                   
                        <img :alt="item.name" :src="item.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="inline-block h-16 mx-4 rounded-md" />
                        <div class="inline-block">
                          <h3 class="text-gray-80">{{ item.name }}</h3>
                          <p class="text-gray-600 text-xs"> 
                            <span v-for="(images, r) in item.details.filter(it => it.warehouse && it.warehouse.is_primary)" :key="r" class="inline-block">
                                  <img :src="images.warehouse.flag" class="w-4 h-4 rounded-full mr-2">
                            </span>
                          </p>
                        </div>
                   </span>
                    <!--<div class="justify-center text-xs text-center bg-blue-100 font-semibold rounded-full text-green-500 ">
                      <p>{{ item.price }} <sup class="text-black">{{wharhouse.currency }}</sup></p>
                    </div>-->
                  </div>
                </div>
              </div>

              <div class="modal-footer px-5 border0-t text-right">
                <button @click="closePopUp"
                  class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">
                  {{$t('cancel')}}
                </button>
                <button @click="ConfirmOperation"
                  class="flex mr-2 text-white mx-auto max-w-sm rounded text-center bg-green-600  py-2 px-8 items-center focus:outline-none md:float-right">
                  {{$t('confirm')}}
                </button>
              </div>
          </vue-modal-2>
          <!-- ******************************************************** -->
          <!--Modal set service seller Shipping -->
          <vue-modal-2 name="ModalChangeSetSettingsSeller" :headerOptions="{title: expeditionData.setting.exist?`Définir les services pour le nouveau warehouse de ce seller`:'Définir le warehouse pour chaque produit'}" :noFooter="true" modalSize="lg" @on-close="$vm2.close('ModalChangeSetSettingsSeller')">
              <loading :active.sync="expeditionData.loading" :is-full-page="fullPage"></loading>
              <div class="bg-white rounded-lg">

                  <div class="relative">
                      <div class="rounded-lg flex">
                          <div class="w-full p-4">
                              <div class="change-status border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                      <p>
                                          <label for="settings" class="bg-white text-gray-700 px-1">Country (warhousing)</label>
                                      </p>
                                  </div> 
                                  <p>
                                  <input v-if="expeditionData.country" type="text" disabled v-model="expeditionData.country" class="py-2 text-xs px-2 outline-none block h-full w-full">
                                </p>
                              </div> 
                              <section class="warehouse" v-if="expeditionData.warhouseParent.exist">   
                                  <div class="flex mt-10 mb-5 items-center text-center">
                                      <hr class="border-gray-300 border-1 w-full rounded-md">
                                      <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir warehouse</span> </label>
                                      <hr class="border-gray-300 border-1 w-full rounded-md">
                                  </div>
                                  <div class="bg-ww">
                                      <h2 class="text-sm font-bold truncate mb-5">Définir le warehouse pour chaque produit (<img v-if="wharhouse" :src="wharhouse.flag" class="h-4 w-5 blob inline-block" :alt="wharhouse.country">)</h2> 
                                          <div v-for="(item, index) in expeditionData.data.details" :key="index" :value="item._id" class="flex w-full space-x-12 h-auto py-1 px-8 rounded border border-gray-600 mb-2">
                                              <div>
                                                  <img
                                                  :alt="item.product.name"
                                                  :src="item.product.picture||'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"
                                                  class="h-16 w-16 rounded-md mx-auto"
                                                  />
                                              </div>
                                              <div class="col-span-2 w-full">
                                                  <h3 class="text-gray-80">{{ item.product.name }}</h3>
                                                  <p>
                                                  <v-select  v-model="item.warehouseParent" :options="checkWarehousingParent" placeholder="Select warehouse" label="name" class="border border-gray-600">
                                                  <template slot="option" slot-scope="option">
                                                      {{ option.name }} <span v-if="option.is_primary">(Principal)</span>
                                                  </template>
                                                  </v-select>
                                              </p>
                                                  
                                              </div>
                                          </div>
                                  </div>
                            </section>  
                                <!--services -->
                            <section v-if="expeditionData.setting.exist">   
                              <div class="flex mt-10 mb-5 items-center text-center">
                                  <hr class="border-gray-300 border-1 w-full rounded-md">
                                  <label class="block font-medium text-sm cursor-pointer text-green-500  w-full"><span class="font-medium text-base text-green-800 dark:text-green-200">Choisir les services</span> </label>
                                  <hr class="border-gray-300 border-1 w-full rounded-md">
                              </div>
                              <div class="bg-ww"> 
                                  <h2 class="text-sm font-bold truncate mb-5">Définir les services pour le nouveau warehouse de ce seller</h2> 
                                  <div class="flex flex-col lg:flex-row">
                                    <div  id="confirmation" @click="activate('confirmation')" :class="{active: expeditionData.setting.confirmation}" class="border border-gray-600 intro-y flex-1 box-1 py-10 cursor-pointer zoom-in">
                                      <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                      <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-outline-lafs/64/000000/external-ic_support-menu-outline-lafs.png"/>
                                      <div class="text-xl font-medium text-center mt-10">Confirmation</div>
                                    </div>
                                    <div id="fulfillement" @click="activate('fulfillement')" :class="{active: expeditionData.setting.fulfillement}" class="border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 mb-5 lg:mb-0 cursor-pointer zoom-in">
                                        <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                        <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-warehouse-business-intelligence-becris-lineal-becris.png"/>
                                        <div class="text-xl font-medium text-center mt-10">Warehouse</div>
                                        <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                        <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                    </div>
                                    <div id="shipping" @click="activate('shipping')" :class="{active: expeditionData.setting.shipping}" class=" border border-gray-600 intro-y flex-1 box-1 py-10 lg:ml-5 cursor-pointer zoom-in">
                                        <div class="check"><span class="feather-icon select-none relative"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check w-5 h-5 text-white bg-green-500 rounded-full shadow"><polyline points="20 6 9 17 4 12"></polyline></svg></span></div>
                                        <img class="feather feather-shopping-bag block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto block w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" src="https://img.icons8.com/external-bartama-glyph-64-bartama-graphic/64/000000/external-Shipping-e-commerce-glyph-bartama-glyph-64-bartama-graphic.png"/>
                                        <div class="text-xl font-medium text-center mt-10">Shipping</div>
                                        <!-- <div class="text-gray-600 dark:text-gray-400 px-10 text-center mx-auto mt-2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. </div>
                                        <div class="flex justify-center"><div class="relative text-5xl font-semibold mt-8 mx-auto"><span class="absolute text-2xl top-0 left-0 text-gray-600 -ml-4">$</span> 120 </div></div>-->
                                    </div>
                                  </div>
                                </div>
                            </section>  
                          </div>
                          </div>    
                        <div class="p-4 flex space-x-4">
                            <a href="#" @click="$vm2.close('ModalChangeSetSettingsSeller')" class="w-1/2 px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm" :disabled="expeditionData.loading">Cancel</a>
                            <button  @click="confirmChangeExpedition" class="w-1/2 px-4 py-3 text-center text-blue-100 bg-blue-600 rounded-lg hover:bg-blue-700 hover:text-white font-bold text-sm relative loidingconfirm" :disabled="expeditionData.loading"><loading :active.sync="expeditionData.loading" :is-full-page="fullPage"></loading>Confirm</button>
                        </div>
                        </div> 
                  
              </div>
          </vue-modal-2>  
        </div>
        <div class="pt-3 flex btn-bottom-toolbar justify-end">
      <button
        :disabled="loading"
        class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
        @click="save">
        {{$t('save')}}
      </button>
  </div>
           
  </div>

</template>

<script>
  export default {
    props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
    data() {
      return {
        formData: {
          numberOfPackages: 1,
          weight: 0,
          date: this.$moment(new Date()).format('yyyy-MM-DDTHH:mm'),
          arrivalDate:null,
          carrier: {
            name: "",
            phone: "",
          },
          details: [],
          status: "expedited",
          country: "",
          seller: "",
          quantity: {
            sent: 0,
          },
          transportMode: "",
        },
        paginate: {
          total: 0,
          currentpage: 1,
          lastpage: 1,
          per_page: 0,
        },
        searchText: "",
        oldstatus: "",
        products: [],
        warehouses:[],
        show_modal: true,
        productselected: [],
        numberItemRepeated: 0,
        sellers: [],
        status: ["expedited", "received", "cancelled"],
        transports: ["road","railway","air", "maritime"],
        options:this.$countries,
        sellerExists:false,
        selectFromContry:"",
        selectToContry:"",
        wharhouse:null,
        loading:false,
        fullPage: false,
        CheckExpeditionRecived:false ,
        expeditionData:{model:null,dataWhrahouseSetting:{},loading:false,data:{},country:null,warhouseParent:{exist:false,warehouse:null},setting:{exist:false,confirmation:false,fulfillement:false,shipping:false,warehouse:null}},
        checkSetting:null,
        checkWarehousingParent:[]
      };
    },
    watch: {
      'idWharhouse': async function(oldVal, newVal) {
         this.idWharhouse=oldVal;  
      },
      'formData.details': {
            handler: function(newValue) {
               // console.log("Person with price_one_kiloOrPackage:" + newValue.price_one_kiloOrPackage + " modified")
                var vm = this;
               if(vm.formData._id) vm.setValue();
               // console.log("New age: " + newValue.age)
            },
            deep: true
        }
  },
    async mounted() {
     // await this.getCurrentSeller();
      //await this.getUser();
      if (this.currentUser.type == "Seller")  this.formData.seller = this.currentUser;

      //await this.getStatus();
      await this.dataToEdit();
      await this.getWarehouses();
      this.dateMin();
      console.log('wharhouse____',this.warehouses)
    },
    methods: {
      setValue: function() {
        console.log('this.formData.details----',this.formData.details)
        if(this.formData.type && this.formData.type=='transfer') this.formData.numberOfPackages= this.formData.details.map(it => parseFloat(it.weightOrPackages)).reduce((prev, next) => prev + next);
        else this.formData.weight= this.formData.details.map(it => parseFloat(it.weightOrPackages)).reduce((prev, next) => prev + next);
        this.formData.fees= this.formData.details.map(it => (parseFloat(it.weightOrPackages)*parseFloat(it.price_one_kiloOrPackage))).reduce((prev, next) => prev + next);
     },
      async CheckQteSentExist(item){
            this.CheckExpeditionRecived=false;
            if(item.status=='received' ){
                for (let i in item.details) {
                    const filters = {
                    product: item.details[i].product._id,
                    expedition: item._id,
                    };
                const res = await this.$server.search("stockHistories", filters);
                    if(typeof res.content.results !== 'undefined') {
                        this.CheckExpeditionRecived=true;
                    }
                }
            }    
    }, 
    activate:function(el){
          this.expeditionData.setting[el] = !this.expeditionData.setting[el];
     },
     activateWarehouse:function(el){
          console.log('el  1111',this.checkWarehousingParent)
          this.checkWarehousingParent.forEach(function (warehouse) {if (warehouse._id.toString() == el._id.toString()) warehouse.fullOf = true;else warehouse.fullOf = false;});
          this.expeditionData.warhouseParent.warehouse=el;
          console.log('el  222',this.checkWarehousingParent)
     },
      async confirmChangeExpedition(){
          if(this.expeditionData.setting.exist && !this.expeditionData.setting.confirmation && !this.expeditionData.setting.fulfillement && !this.expeditionData.setting.shipping){
              alert("Les services est obligatoir", "warning");
              return false;
          }
           if(this.expeditionData.warhouseParent.exist){
                //check warehouse if select
                for (let index = 0; index < this.expeditionData.data.details.length; index++) {
                    const expeditionLine = this.expeditionData.data.details[index];
                    if (!expeditionLine.warehouseParent) {
                        alert(`Warehouse est obligatoir pour ce produit : ${expeditionLine.product.name}`, "warning");
                        return false;
                        break;
                    }else expeditionLine.warehouseParentName=expeditionLine.warehouseParent.name||expeditionLine.warehouseParent;
                }
          }
          this.loading=true;
          const data = await this.$server.update("expeditions",this.expeditionData);
          this.loading=false;
          if (data && data._id) {
              this.checkSetting=null;
              this.expeditionData.setting={exist:false,confirmation:false,fulfillement:false,shipping:false,warehouse:null},
              this.expeditionData.warhouseParent={exist:false,warehouse:null},
              this.$vm2.close('ModalChangeSetSettingsSeller');
              this.formData.country=this.$f.getCountryNameByCode(this.options,this.selectFromContry);
              this.formData.country_to=this.$f.getCountryNameByCode(this.options,this.selectToContry);
              alert(this.$t('expedition_updated'), "success");
              await this.dataToEdit();
          }else   alert(data, "warning");
      },
     async saveProductPrice(product){
       if(product._id){
            console.log('wharhouse p___________',product)
            const resProductW=await this.getElementInArray(product.details,this.wharhouse._id);
            if(!resProductW) product.details.push({price:0,warehouse:this.wharhouse._id})
            console.log('wharhouse p___________',this.wharhouse._id)
            await this.changePrice(product.details,this.wharhouse._id,product.newPrice);
             console.log('wharhouse p___________',product)
            this.loading=true;
            const data = await this.$server.update("products", product);
            if (data._id) {
              this.loading=false;
              product.price = parseInt(product.newPrice);
              alert(this.$t('product_updated'), "success");
            }
        }
      },
      async changePrice(data, value, price ) {
        for (var i in data) {
          if (data[i].warehouse == value.toString()) {
              data[i].price = price;
              break; //Stop this loop, we found it!
          }
        }
      },
      async getwharhouse(country){
          const resW = await this.$server.get("warehouses", { country: country}); 
          if(resW.content) return  resW.content;
      },
      searchSellers: _.debounce(async function(search,event) {
       if(this.selectToContry){
          const filter = {
              type: "Seller",
              limit:this.limit,
              countries:this.selectToContry,
              name: search,
          };
          const res= await this.$server.find("users",filter);

          if (res.content.results) this.sellers = res.content.results;
          else this.sellers = [];
       }else alert('Please select a country !', "warning");
      }, 500),
      dateMin(){ 
          // document.getElementById('dateexp').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD'));
          // if(this.currentUser.type != "Seller")  document.getElementById('dateexpar').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD')); 
      },
      exceptDes(text){
         if (text && text.length > 60) text = text.substring(0, 60) + "...";
       return text;
      },
      setSelectedFromContry(contry) {
         if(contry) {
            this.formData.country=contry.name;
           this.selectFromContry=contry.code;
         }
      },
      async setSelectedToContry(contry) {
         if(contry){
            this.formData.country_to=contry.name;
            this.selectToContry=contry.code;
            this.products=[];this.formData.details=[];
            this.wharhouse=await this.getwharhouse(contry.code);
            await this.getSellers(contry.code);

            const istransfer = this.warehouses.find((o) => o.code === this.selectFromContry);
             if(istransfer) this.formData.type='transfer';
             else this.formData.type='expedition';
             console.log('istransfer---------',istransfer)
          }
      },
      setStatusSelected(status) {
        if (status) {
          this.formData.status = status;
        } else {
          this.formData.status = "";
        }
      },

      async getCurrentSeller() {
        const res = await this.$server.me("users");
        if (res.content) {
          const currentUser = res.content;
          if (currentUser.type == "Seller")  this.formData.seller = currentUser;
        } 
      },
      async getSellers(country) {
        if (this.currentUser.type !== "Seller"){
          const res = await this.$server.search("users", { type: "Seller",countries:country });
          if (res && res.content.results) {
            this.sellers = res.content.results;
            this.sellerExists=true;
          }else  this.sellers =[];
        }
      },
      async getUser() {
        const res = await this.$server.me("users");
        console.log(res);
        if (res.content) this.currentUser = res.content;
        else this.currentUser = {};
      },
      async getProducts(filters) {
        const resW = await this.$server.get("warehouses", { country: this.selectToContry}); 
        if (this.formData.seller){
             filters.seller = this.formData.seller._id;
             filters.limit=200;
        
        const res = await this.$server.search("products", filters);
        if (res && res.content.results) {
          this.products = res.content.results;
          this.paginate.total = res.content.total;
          this.paginate.lastpage = res.content.last_page;
          this.paginate.per_page = res.content.per_page;
          this.paginate.currentpage = res.content.current_page;

          for (let i in this.products) {
            if (this.products[i].seller) this.products[i].seller = this.products[i].seller._id;
            const resProductW=await this.getElementInArray(this.products[i].details,resW.content._id);
            if(resProductW) this.products[i].price = resProductW.price||0;
            else this.products[i].price=0;
            this.products[i].newPrice =0;
            

          }
        } else this.products = [];
        } 
      },
      async getElementInArray(data,value){
        var result  = data.filter(function(o){
            let whar=o.warehouse;
            if(o.warehouse._id) whar=o.warehouse._id;
            return whar == value.toString();
        } );
        return result? result[0] : null; // or undefined
      },
      async save() {
        if (this.formData._id) {
          const istransfer = this.warehouses.find((o) => o.code === this.selectFromContry);
          if(istransfer) this.formData.type='transfer';
          else this.formData.type='expedition';

          if(this.formData.type === 'transfer'){
                //check warehouse if select
                for (let index = 0; index < this.formData.details.length; index++) {
                    const expeditionLine = this.formData.details[index];
                    if ((expeditionLine.productToTransfer && !expeditionLine.productToTransfer._id) || !expeditionLine.productToTransfer) {
                        alert(`Transférer Produit required : ${expeditionLine.product.name}`, "warning");
                        this.loading=false;
                        return false;
                        break;
                    }
                }
           }

           this.formData.country=this.selectFromContry;
           this.formData.country_to=this.selectToContry;
           this.loading=true;
          //check warhouse parent
            const resW = await this.$server.search("warehouses", { country: this.selectToContry,all:true,limit:10}); 
             console.log('resW--------------',resW)
           if(resW.content && resW.content.results) {
                this.checkWarehousingParent=resW.content.results.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0));
                this.checkWarehousingParent.forEach(x => { x.fullOf=false; });
            }else this.checkWarehousingParent=[];
           
           //check user services
          console.log('this.formData.seller------',this.formData)
          console.log('this.wharhouse.seller------',this.wharhouse)
          this.checkSetting=await this.getElementInArray(this.formData.seller.settings,this.wharhouse._id||this.wharhouse)
          console.log('this.checkSetting find 11',this.checkSetting)
          if(this.formData.status=='received' && (this.checkWarehousingParent.length>1 || !this.checkSetting || (this.checkSetting && !this.checkSetting.confirmation && !this.checkSetting.fulfillement && !this.checkSetting.shipping))){
              if(!this.checkSetting || (this.checkSetting && !this.checkSetting.confirmation && !this.checkSetting.fulfillement && !this.checkSetting.shipping)) this.expeditionData.setting.exist=true;
              if(this.checkWarehousingParent.length>1) this.expeditionData.warhouseParent.exist=true;
              this.expeditionData.country=this.$f.getCountryNameByCode(this.options,this.formData.country_to);
              this.expeditionData.data=this.formData;
              this.$vm2.open('ModalChangeSetSettingsSeller');
              this.loading=false;
              console.log('expeditionData------',this.expeditionData)
              return false
          }   


         

          const data = await this.$server.update("expeditions", this.formData);
          this.loading=false;
          if (data && data._id) {
            this.formData.country=this.$f.getCountryNameByCode(this.options,this.selectFromContry);
            this.formData.country_to=this.$f.getCountryNameByCode(this.options,this.selectToContry);
            alert(this.$t('expedition_updated'), "success");
            await this.dataToEdit();
          }else alert(data, "warning");
        } else {
          
          if (this.checkData()) {

            const istransfer = this.warehouses.find((o) => o.code === this.selectFromContry);
             if(istransfer) this.formData.type='transfer';
             else this.formData.type='expedition';
            
             if(this.formData.type === 'transfer'){
                //check warehouse if select
                for (let index = 0; index < this.formData.details.length; index++) {
                    const expeditionLine = this.formData.details[index];
                    if ((expeditionLine.productToTransfer && !expeditionLine.productToTransfer._id) || !expeditionLine.productToTransfer) {
                        alert(`Transférer Produit required : ${expeditionLine.product.name}`, "warning");
                        this.loading=false;
                        return false;
                        break;
                    }
                }
             }

             for (const i in this.formData.details) {
                if (!this.formData.details[i].merchandise_value || Number.parseInt(this.formData.details[i].merchandise_value) === 0) {
                  alert("Prix d'achat est obligatoire", 'warning');
                  this.loading = false;
                  return false;
                  break;
                }
                if (!this.formData.details[i].product.picture) {
                    alert(`Image de ce produit est obligatoire: ${this.formData.details[i].product.name}`, 'warning');
                    this.loading = false;
                    return false;
                    break;
                }
             }
        
            

             this.formData.country=this.selectFromContry;
             this.formData.country_to=this.selectToContry;
             console.log('country-------',this.formData.country)
             console.log('warehouses-------',this.warehouses)
            
            this.formData.countryName= this.$f.getCountryNameByCode(this.options,this.formData.country)
            this.formData.country_toName= this.warehouses.find((e) => e.code === this.formData.country_to.toString().toUpperCase()).name;
            this.loading=true;
            const data = await this.$server.create("expeditions", this.formData);
            this.loading=false;
            if (data && data._id) {
              this.selectFromContry=this.selectToContry="";
              this.formData = {
                carrier: {},
                details: [],
              };
               if (this.currentUser.type === "Seller") this.formData.seller = this.currentUser._id;
               alert(this.$t('expedition_created'), "success");
            }else alert(data, "warning");
          } else {
            alert(this.$t('all_required_field'), "warning");
          }
        }
      },
      async getWarehouses(){
        const filter = {limit:10};
        const res = await this.$server.search("warehouses",filter);
        if (res.content.results) {
            let data = res.content.results;
            for(let i in data){
              this.warehouses.push({name:data[i].name,code:data[i].country})
            }
        } else this.warehouses = [];
      }, 
      getQuantity() {
        this.formData.quantity.sent = 0;
        for (let i = 0; i < this.formData.details.length; i++) {
          this.formData.quantity.sent += this.formData.details[i].quantity;
        }
      },
      checkData() {
        if (
          this.formData.numberOfPackages &&
          this.formData.carrier.name &&
          this.formData.country &&
          this.formData.country_to &&
          this.formData.transportMode &&
          this.formData.date &&
          this.formData.transitaire &&
          this.formData.details.length > 0 &&
          this.formData.seller
        )
          return true;
        else return false;
      },
      removeProduct(data, index) {
        this.$confirm("Are you sure you want to delete this product?").then(
          async (res) => {
            let pos = this.formData.details
              .map(function (e) {
                return e.product._id;
              })
              .indexOf(data._id);

            this.formData.details.splice(pos, 1);
            data.product.check = false;
            alert(this.$t('product_deleted'), "success");
          }
        );
      },

      addProductsToTemp(data, index) {
        if (data.check) {
          const detail = {
            product: data,
            productToTransfer:{},
            quantity: {
              sent: 1,
            },
          };

          let found = 0;
          for (let i = 0; i < this.formData.details.length; i++) {
            if (this.formData.details[i].product._id == data._id) {
              found = found + 1;
              this.numberItemRepeated = this.numberItemRepeated + 1;
            }
          }
          if (found >= 1) {
          } else {
            this.productselected.push(detail);
          }
        } else {
          let pos = this.productselected
            .map(function (e) {
              return e.product._id;
            })
            .indexOf(data._id);
          this.productselected.splice(pos, 1);
        }
      },
      addQuantity(index) {
        this.formData.details[index].quantity.sent++;
      },
      removeQuantity(index) {
        if (this.formData.details[index].quantity.sent <= 1) {
        } else {
          this.formData.details[index].quantity.sent--;
        }
      },
      ViderTable() {
        this.productselected = [];
      },
       ViderProductTable() {
       this.formData.details= [];
       },
     async OpenPopUp() {
         if(!this.formData.country_to){
            alert(this.$t('select_country'), "warning");
            return false;
        } 
         if (this.formData.seller) {
            await this.getProducts({});
             if (this.products.length > 0) {
                  this.$vm2.open("Modal_products");
             }else{
                 if(this.currentUser.type != 'Seller') alert(this.$t('no_products_seller'), "warning");
                 else  alert(this.$t('no_product_added'), "warning");  
             }
         }else{
          alert(this.$t('must_select_seller'), "warning");
          }
      },
      ConfirmOperation() {
        let fount = 0;
        if (this.numberItemRepeated > 0) {
          alert(this.$t('product_already_added'), "success");
        } else {
          for (let i = 0; i < this.productselected.length; i++) {
            this.formData.details.push(this.productselected[i]);
          }
        }
        this.productselected = [];

        this.numberItemRepeated = 0;
        this.$vm2.close("Modal_products");
      },
      closePopUp() {
       this.$vm2.close("Modal_products");
      },
      async dataToEdit() {
        if (this.$route.params.id) {
          const editData = await this.$server.get("expeditions", {
            id: this.$route.params.id,
          });
          this.formData = editData.content;
          this.oldstatus= this.formData.status
          this.wharhouse=await this.getwharhouse(this.formData.country_to);
          await this.CheckQteSentExist(this.formData);
          this.selectFromContry=this.formData.country;
          this.formData.country=this.$f.getCountryNameByCode(this.options,this.formData.country);
          this.selectToContry=this.formData.country_to;
          this.formData.country_to=this.$f.getCountryNameByCode(this.options,this.formData.country_to);
          this.formData.date = this.$moment(this.formData.date).format('yyyy-MM-DDTHH:mm');
          if(this.formData.arrivalDate) this.formData.arrivalDate = this.$moment(this.formData.arrivalDate).format('yyyy-MM-DDTHH:mm');
          await this.getSellers(this.formData.country_to);
        }
      },
    },

    computed: {
      resultSearchUsers() {
        if (this.searchText) {
          return this.products.filter((product) => {
            var regex = new RegExp(this.searchText, "i");

            return regex.test(product.name);
          });
        } else {
          return this.products;
        }
      },
    },

    /************************************* Pagination **************************************************/
  };
</script>

<style scoped>

.btn-bottom-toolbar{
  margin-left: -15px;
}
.ware_prin::before {
    width: 23px;
}
.ware_prin {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    margin-left: 7px;
}
.flag-img{
    display: inline-block;
    height: 16px;
    width: 16px;
    vertical-align: text-top;
    line-height: 16px;
    margin-right: 5px;
}
.left-3 {
    left: .75rem;
}
.top-2 {
    top: .1rem;
}
.vs__dropdown-toggle {
    border: none;
}  
  .modal-header {
    background: #226faa;
    background: -webkit-gradient(linear,
        left top,
        right top,
        from(#226faa),
        color-stop(37%, #2989d8),
        to(#72c0d3));
    background: linear-gradient(to right, #226faa 0, #2989d8 37%, #72c0d3 100%);
    border-radius: 6px;
    color: #fff;
    padding: 18px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: transparent;
    border-bottom: 1px solid #e5e5e5;
  }

  .table.item thead {
    background: #3182ce4f;
    color: #fff;
    border: 0;
  }

  .toggle__dot {
    transition: all 0.3s ease-in-out;
  }

  .seller-select .vs__dropdown-toggle {
    border: none;
  }

  input:checked~.toggle__dot {
    transform: translateX(100%);
    background-color: #68d391;
  }

  /* animation for vue transition tag */
  dialog[open] {
    animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
  }

  dialog::backdrop {
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
    backdrop-filter: blur(3px);
  }

  @keyframes appear {
    from {
      opacity: 0;
      transform: translateX(-3rem);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .toggle__dot {
  top: -.25rem;
  left: -.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  background-color: #63b3ed;
}
</style>